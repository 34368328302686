import React from 'react';
import LoadingIcon from 'assets/img/icons/loading.svg';
// causing build error
// import LoadingIcon from 'features/generic/components/LoadingIcon';

import {StyledSvg, SvgContainer, Container, Text} from './styles';

const Loading = (props) => {
    return (
        <Container style={props.style}>
            <SvgContainer>
                <StyledSvg>
                    <LoadingIcon />
                </StyledSvg>
            </SvgContainer>
            {props.message && <Text style={props.messageStyle}>{props.message}</Text>}
        </Container>
    );
};

export default Loading;
