import {COLORS} from 'core/styles';

export let content = {
    backgroundColor: '#fff',
    minHeight: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
};

export let imageContainer = {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '30px',
};

export let svg = {
    marginRight: '30px',
    width: '100px',
    height: '100px',
};

export let oops = {
    fontSize: '80px',
    marginBottom: '-15px',
    fontWeight: 500,
};

export let apologize = {
    fontSize: '30px',
    marginTop: '0',
    marginBottom: '5px',
};

export let message = {
    fontSize: '16px',
    margin: '0 0 30px',
};

export let back = {
    cursor: 'pointer',
    backgroundColor: COLORS.primary,
    color: '#fff',
    fontWeight: 'bold',
    padding: '10px 15px',
    textAlign: 'center',
    fontSize: '16px',
    marginBottom: '25px',
};

export let dashboardLink = {
    color: COLORS.primary,
    fontWeight: 'bold',
    textDecoration: 'underline',
};
