'use client';
import * as Sentry from '@sentry/nextjs';
import {useEffect} from 'react';
import NotFound from 'features/generic/components/NotFound/NotFound';

export default function GlobalError({error, reset}) {
    useEffect(() => {
        Sentry.captureException(error);
        console.log('global-error.js caught an error');
        console.error(error);
    }, [error]);

    return (
        <html>
            <body>
                <NotFound statusCode={500} error={error} reset={reset} />
            </body>
        </html>
    );
}
