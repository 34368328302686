import {COLORS, bp, MAX_WIDTH} from 'core/styles';
import styled from 'styled-components';

export let ContentContainer = styled.div`
    box-shadow: 0 -1px 1px rgba(0, 0, 0, 0.15);
    padding: 15px;
    background-color: ${COLORS.grayLight};

    ${bp('md')} {
        padding: 30px;
    }
    ${(props) => (props.$css ? props.$css : '')};
`;

export let ContentInner = styled.div`
    max-width: ${MAX_WIDTH};
`;
