import React from 'react';

import {ContentContainer, ContentInner} from './styles';
//import './Content.scss';

const Content = (props) => {
    return (
        <ContentContainer $css={props.$css} className={props.className}>
            <ContentInner>{props.children}</ContentInner>
        </ContentContainer>
    );
};

export default Content;
